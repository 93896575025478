import React from "react";
import Layout from "../components/layout";
import ListPart from "../components/list-part";
import MangaDesc from "../components/manga-decs";
import Seo from "../components/seo";
import { Link, graphql } from "gatsby";

export default function Home({ data }) {
  let mangas = data.mangaData.data;

  mangas.sort((mA, mB) => {
    return mB.free_episode_count - mA.free_episode_count;
  });

  mangas = mangas.slice(0, 6);
  const updated_at = data.siteUpdatedAt.data;
  return (
    <Layout>
      <Seo
        title={null}
        description={
          "ネット上で読めるWeb漫画サイトの更新情報・無料掲載話をまとめているサイトです。毎日深夜2時頃更新。"
        }
      />
      <h1>漫画更新チェッカー</h1>
      <section>
        <p>
          ネット上で読めるWeb漫画サイトの更新情報・無料掲載話をまとめているサイトです。
          <br />
          <br />
          毎日深夜2時頃更新。
          <br />
          ※最終更新：{updated_at}
          <br />
          ※対応サイト：少年ジャンプ+、マガポケ、コミックDAYS。
        </p>
      </section>
      <section>
        <h2>おすすめ</h2>
        <div className="osusumeParent">
          {mangas.map((manga) => (
            <ListPart
              key={manga.slug}
              to={`/manga/${manga.slug}`}
              isNewTab={false}
              imgSrc={manga.imageUrl}
              name={manga.name}
              desc={<MangaDesc manga={manga} />}
              up={manga.latest}
            />
          ))}
        </div>
        <div className="buttonDiv">
          <Link to="/list" className="btn as-normal">
            もっと見る
          </Link>
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`
  query {
    mangaData(data: {}) {
      id
      data {
        slug
        name
        author
        imageUrl
        description
        latest
        free_episode_count
        epiList {
          episodes {
            date
            title
            thumbnail
            link
          }
          mediaId
        }
      }
    }
    siteUpdatedAt {
      data
    }
  }
`;
